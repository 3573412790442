import "./App.css";
import Tile from "./Tile";
import _ from "lodash";

let arr = [];
for (let i = 1; i < 26; i++) {
  arr.push(i);
}
arr = _.shuffle(arr);

function App() {
  return (
    <div className="container">
      <h1>Bingo</h1>
      <div className="board">
        {arr.map((tile) => {
          return <Tile key={tile} tile={tile} />;
        })}
      </div>
    </div>
  );
}

export default App;
