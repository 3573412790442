import { useState } from "react";

export default function Tile({ tile }) {
  const [clicked, setClicked] = useState(false);
  function handleClick() {
    setClicked((clicked) => !clicked);
  }
  return (
    <div onClick={handleClick} className={`tile ${clicked && "clicked"} `}>
      <div>{tile}</div>
    </div>
  );
}
